export default {
  filterIcon: require("./icon/filter-icon.png"),
  userIcon: require("./icon/user-icon.png"),
  kcihistory: require("./icon/kcihistory-icon.png"),
  kcimove: require("./icon/kcimove-icon.png"),
  kcicopy: require("./icon/kcicopy-icon.png"),
  kciHisApprove: require("./icon/kciHis-approve-icon.png"),
  kciApprove: require("./icon/kciapprove-icon.png"),
  ickLogo: require("./icon/ICK_LOGO.jpg"),
};
