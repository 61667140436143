import icon from "../resource/icon";

export const CompanyList: any = [
  { key: 1, company: "ICPL" },
  { key: 2, company: "ICPF" },
  { key: 3, company: "ICK" },
  { key: 4, company: "ICPI" },
];
export const COMPAY_MAPPING_DES: any = {
  "1": "ICPL",
  "2": "ICPF",
  "3": "ICK",
  "4": "ICPI",
};
export const COMPANY_CODE_MAPPING_DES: any = {
  ICPL: 1,
  ICPF: 2,
  ICK: 3,
  ICPI: 4,
};
export const COMPANY_CODE_MAPPING_LOGO: any = {
  ICPL: "https://cdn-sellcoda.iconkaset.com/company_logo/icpi_logo.png",
  ICPF: "https://cdn-sellcoda.iconkaset.com/company_logo/icpf_logo.png",
  ICK: icon.ickLogo,
  ICPI: "https://cdn-sellcoda.iconkaset.com/company_logo/icpl.png",
};
