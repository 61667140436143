import React, { useEffect, useState } from "react";
import Layout, { Header } from "antd/lib/layout/layout";
import { Avatar, Badge, Col, Menu, Popover, Row } from "antd";
import {
  LayoutFilled,
  HomeFilled,
  HddFilled,
  SettingFilled,
  UserOutlined,
  ContactsFilled,
  SignalFilled,
  DashboardFilled,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import Text from "../Text/Text";
import color from "../../resource/color";
import { Outlet, useNavigate } from "react-router";
import { AuthDatasource } from "../../datasource/loginDatasource";
import { useSearchParams } from "react-router-dom";
import { PermissionReportDatasource } from "../../datasource/permissionReportDatasource";
import { EmployeeApproveDatasource } from "../../datasource/EmploeeApprove";
import { COMPAY_MAPPING_DES } from "../../definitions/Company";

const Layouts: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") || "";
  const permission = localStorage.getItem("rainbowPermission") || "";
  const profileData = JSON.parse(localStorage.getItem("rainbowProfile")!);

  useEffect(() => {
    if (token) {
      localStorage.setItem("rainbowToken", token);
    }
  }, [token]);

  const [profile, setProfile] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [checkManager, setCheckManager] = useState<boolean>(false);

  const login = async () => {
    await AuthDatasource.getEmployeeByToken(
      localStorage?.getItem("rainbowToken") || ""
    ).then(async (emp) => {
      if (emp?.success) {
        let img = emp?.responseData?.imagePath?.split("\\");
        img = img && [img.length - 1];
        localStorage.setItem(
          "rainbowProfile",
          JSON.stringify({
            ...emp.responseData,
            linkImage:
              `http://system.icpladda.com/EmployeeProfileImage/${img}` || "",
          })
        );
        setProfile({
          ...emp.responseData,
          linkImage:
            `http://system.icpladda.com/EmployeeProfileImage/${img}` || "",
        });
        await PermissionReportDatasource.getPermissionByEmpId(
          emp.responseData.employeeId
        ).then((res) => {
          if (res.success) {
            localStorage.setItem(
              "rainbowPermission",
              JSON.stringify(res.responseData)
            );
          } else {
            localStorage.setItem("rainbowPermission", JSON.stringify(null));
          }
        });
        setLoading(false);
        const manager = await EmployeeApproveDatasource.getEmployeeApprove(
          emp.responseData.employeeId
        ).then((res) => {
          return res.responseData;
        });
        setCheckManager(manager?.length > 0 ? true : false);
      } else {
        window.location.href = "https://system.icpladda.com/";
      }
    });
  };

  useEffect(() => {
    login();
  }, []);

  const menus: MenuProps["items"] = checkManager
    ? [
        {
          label: <Text fontWeight={700}>Home</Text>,
          key: "",
          icon: <HomeFilled style={{ fontSize: "25px" }} />,
        },
        {
          label: <Text fontWeight={700}>Self-Reflection</Text>,
          key: "self",
          icon: <LayoutFilled style={{ fontSize: "25px" }} />,
        },
        {
          label: <Text fontWeight={700}>PMS</Text>,
          key: "pms",
          icon: <HddFilled style={{ fontSize: "25px" }} />,
        },
        {
          label: <Text fontWeight={700}>KCI</Text>,
          key: `kci/${
            COMPAY_MAPPING_DES[`${profileData?.companyId}`] ||
            profileData?.companyList?.find(
              (x: any) => `${x?.companyId}` === `${profileData?.companyId}`
            ).companyCode
          }`,
          icon: <DashboardFilled style={{ fontSize: "25px" }} />,
        },
      ]
    : [
        {
          label: <Text fontWeight={700}>Home</Text>,
          key: "",
          icon: <HomeFilled style={{ fontSize: "25px" }} />,
        },
        {
          label: <Text fontWeight={700}>Self-Reflection</Text>,
          key: "self",
          icon: <LayoutFilled style={{ fontSize: "25px" }} />,
        },
        {
          label: <Text fontWeight={700}>KCI</Text>,
          key: `kci/${
            COMPAY_MAPPING_DES[`${profileData?.companyId}`] ||
            profileData?.companyList?.find(
              (x: any) => `${x?.companyId}` === `${profileData?.companyId}`
            ).companyCode
          }`,
          icon: <DashboardFilled style={{ fontSize: "25px" }} />,
        },
      ];
  const approvalMenu: any = {
    label: <Text fontWeight={700}>Approval Setting</Text>,
    key: "approvalEmployee",
    icon: <ContactsFilled style={{ fontSize: "25px" }} />,
  };
  const settingMenu: any = {
    label: <Text fontWeight={700}>Conditions Setting</Text>,
    key: "setting",
    icon: <SettingFilled style={{ fontSize: "25px" }} />,
    children: [
      {
        label: <Text fontWeight={700}>Self-Reflection Condition</Text>,
        key: "selfCondition",
        icon: <SettingFilled style={{ fontSize: "25px" }} />,
      },
      {
        label: <Text fontWeight={700}>Score Condition</Text>,
        key: "scoreCondition",
        icon: <SettingFilled style={{ fontSize: "25px" }} />,
      },
    ],
  };
  const reportMenu: any = {
    label: <Text fontWeight={700}>Reports</Text>,
    key: "report",
    icon: <SignalFilled style={{ fontSize: "25px" }} />,
    children: checkManager
      ? [
          {
            label: <Text fontWeight={700}>PMS Report</Text>,
            key: "managerPmsReport",
            icon: <HddFilled style={{ fontSize: "25px" }} />,
          },
          {
            label: <Text fontWeight={700}>Self-Reflection Summary</Text>,
            key: "selfReport",
            icon: <SignalFilled style={{ fontSize: "25px" }} />,
          },
          {
            label: <Text fontWeight={700}>PMS Summary</Text>,
            key: "pmsReport",
            icon: <SignalFilled style={{ fontSize: "25px" }} />,
          },
          // {
          //   label: <Text fontWeight={700}>KCI Report</Text>,
          //   key: "kciReport",
          //   icon: <PieChartFilled style={{ fontSize: "25px" }} />,
          // },
          {
            label: <Text fontWeight={700}>Permission Report Setting</Text>,
            key: "permissionReportSetting",
            icon: <SettingFilled style={{ fontSize: "25px" }} />,
          },
        ]
      : [
          {
            label: <Text fontWeight={700}>Self-Reflection Summary</Text>,
            key: "selfReport",
            icon: <SignalFilled style={{ fontSize: "25px" }} />,
          },
          {
            label: <Text fontWeight={700}>PMS Summary</Text>,
            key: "pmsReport",
            icon: <SignalFilled style={{ fontSize: "25px" }} />,
          },
          // {
          //   label: <Text fontWeight={700}>KCI Report</Text>,
          //   key: "kciReport",
          //   icon: <PieChartFilled style={{ fontSize: "25px" }} />,
          // },
          {
            label: <Text fontWeight={700}>Permission Report Setting</Text>,
            key: "permissionReportSetting",
            icon: <SettingFilled style={{ fontSize: "25px" }} />,
          },
        ],
  };

  const menuPermission = () => {
    const conditionG = [4, 1, 16, 1489];
    const approveG = [
      4, 1, 16, 1489, 2, 1302, 1517, 1315, 1484, 1146, 1207, 1742, 1737,
    ];
    const reportG = [4, 1, 16, 1489];
    const setting = conditionG.includes(Number(profile.employeeId));
    const approvalEmployee = approveG.includes(Number(profile.employeeId));
    const report = reportG.includes(Number(profile.employeeId));
    if (setting) {
      menus.push(settingMenu);
    }
    if (approvalEmployee) {
      menus.push(approvalMenu);
    }
    if (checkManager) {
      if (report) {
        menus.push(reportMenu);
        let jsonPermission = JSON.parse(permission);
        if (jsonPermission) {
          jsonPermission = jsonPermission?.permission;
          if (jsonPermission?.length > 1) {
            const mapMenu: any = menus.map((x) => {
              const findReport = x?.key === "report";
              if (findReport) {
                return { ...x, children: reportMenu.children };
              } else {
                return x;
              }
            });
            return mapMenu;
          } else {
            jsonPermission = jsonPermission[0]?.reportName;
            const mapPermission = {
              ...reportMenu,
              children: jsonPermission?.includes("Self")
                ? reportMenu?.children.filter((x: any) => x.key !== "pmsReport")
                : reportMenu?.children.filter(
                    (x: any) => x.key !== "selfReport"
                  ),
            };
            menus.push(mapPermission);
            return menus;
          }
        } else {
          const mapMenu: any = menus.map((x) => {
            const findReport = x?.key === "report";
            if (findReport) {
              return {
                ...x,
                children: reportMenu.children.filter(
                  (x: any) => x.key === "permissionReportSetting"
                ),
              };
            } else {
              return x;
            }
          });
          return mapMenu;
        }
      } else {
        let jsonPermission = JSON.parse(permission);
        jsonPermission = jsonPermission?.permission;
        if (jsonPermission) {
          if (jsonPermission?.length > 1) {
            const mapPermission = {
              ...reportMenu,
              children: reportMenu?.children.filter(
                (x: any) => x.key !== "permissionReportSetting"
              ),
            };
            menus.push(mapPermission);
            return menus;
          } else {
            jsonPermission = jsonPermission[0]?.reportName;
            const mapPermission = {
              ...reportMenu,
              children: jsonPermission?.includes("Self")
                ? reportMenu?.children.filter((x: any) => x.key !== "pmsReport")
                : reportMenu?.children.filter(
                    (x: any) => x.key !== "selfReport"
                  ),
            };
            menus.push(mapPermission);
            return menus;
          }
        } else {
          const mapPermission = {
            ...reportMenu,
            children: reportMenu?.children.filter(
              (x: any) => x.key === "managerPmsReport"
            ),
          };
          menus.push(mapPermission);
          return menus;
        }
      }
    } else {
      if (report) {
        let jsonPermission = JSON.parse(permission);
        jsonPermission = jsonPermission?.permission;
        if (jsonPermission) {
          if (jsonPermission?.length > 1) {
            const mapPermission = {
              ...reportMenu,
              children: reportMenu?.children.filter(
                (x: any) => x.key !== "managerPmsReport"
              ),
            };
            menus.push(mapPermission);
            return menus;
          } else {
            jsonPermission = jsonPermission[0]?.reportName;
            const mapPermission = {
              ...reportMenu,
              children: jsonPermission?.includes("Self")
                ? reportMenu?.children.filter((x: any) => x.key !== "pmsReport")
                : reportMenu?.children.filter(
                    (x: any) => x.key !== "selfReport"
                  ),
            };
            menus.push(mapPermission);
            return menus;
          }
        } else {
          return menus;
        }
      }
    }
    return menus;
  };

  const text = (
    <Text fontSize={16} fontWeight={700} color="white">
      ประวัติ
    </Text>
  );
  const content = (
    <div>
      <Text fontSize={14} fontWeight={500} color="white">
        Employee Code : {profile?.employeeCode}
      </Text>
      <br />
      <Text fontSize={14} fontWeight={500} color="white">
        Nick Name: {profile?.nickname}
      </Text>
      <br />
      <Text fontSize={14} fontWeight={500} color="white">
        Position: {profile?.position?.positionName}
      </Text>
    </div>
  );
  if (loading) {
    return <></>;
  } else {
    return (
      <>
        <Layout>
          <Header
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              width: "100%",
              alignItems: "center",
              backgroundColor: color.white,
            }}
          >
            <Row>
              <Col span={23}>
                <Menu
                  mode="horizontal"
                  items={menuPermission()}
                  theme="light"
                  defaultOpenKeys={["self"]}
                  onClick={(e) => navigate(`/${e.key}`)}
                />
              </Col>
              <Col span={1}>
                <Badge count={profile?.jbType}>
                  <Popover
                    placement="bottomRight"
                    title={text}
                    content={content}
                    trigger="click"
                    color={"black"}
                  >
                    <Avatar
                      src={profile.linkImage}
                      icon={<UserOutlined />}
                      shape="square"
                      size={45}
                      style={{ backgroundColor: color.orange }}
                    />
                  </Popover>
                </Badge>
              </Col>
            </Row>
          </Header>
          <Outlet />
        </Layout>
      </>
    );
  }
};

export default Layouts;
