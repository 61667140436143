import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import Layouts from "../components/Layout/Layouts";
import IndexHome from "../page/Home/IndexHome";
import PmsReport from "../page/Report/PmsReport";
import SelfReflectionReport from "../page/Report/SelfReflectionReport";
import IndexSelfReflection from "../page/SelfReflection/IndexSelfReflection";
import ApprovalEmplyee from "../page/Setting/ApprovalEmployee";
import PermissionReportSetting from "../page/Setting/PermissionReprotSetting";
import ScoreConditon from "../page/Setting/ScoreCondition";
import SelfReflectionConditon from "../page/Setting/SelfCondition";
import IndexSupervisor from "../page/Supervisor/IndexSupervisor";
import ManagerPmsReport from "../page/Report/ManagerPmsReport";
import IndexKci from "../page/KCI/IndexKci";
import CreateKci from "../page/KCI/CreateKci";
import KciReport from "../page/Report/KciReport";

const pageList: RouteObject[] = [
  {
    path: "/",
    element: <Layouts />,
    children: [
      {
        path: "/",
        element: <IndexHome />,
        children: [],
      },
      {
        path: "/self",
        element: <IndexSelfReflection />,
        children: [],
      },
      {
        path: "/pms",
        element: <IndexSupervisor />,
        children: [],
      },
      {
        path: "/dashboard",
        element: <IndexHome />,
        children: [],
      },
      {
        path: "/setting",
        element: <IndexHome />,
        children: [],
      },
      {
        path: "/selfCondition",
        element: <SelfReflectionConditon />,
        children: [],
      },
      {
        path: "/scoreCondition",
        element: <ScoreConditon />,
        children: [],
      },
      {
        path: "/approvalEmployee",
        element: <ApprovalEmplyee />,
        children: [],
      },
      {
        path: "/permissionReportSetting",
        element: <PermissionReportSetting />,
        children: [],
      },
      {
        path: "/selfReport",
        element: <SelfReflectionReport />,
        children: [],
      },
      {
        path: "/pmsReport",
        element: <PmsReport />,
        children: [],
      },
      {
        path: "/managerPmsReport",
        element: <ManagerPmsReport />,
        children: [],
      },
      {
        path: "/kci/:companyCode",
        element: <IndexKci />,
        children: [],
      },
      {
        path: "/createKci/:year/:empId/:companyCode",
        element: <CreateKci />,
        children: [],
      },
      {
        path: "/kciReport",
        element: <KciReport />,
        children: [],
      },
    ],
  },
];
export const Routes = () => {
  const router = createBrowserRouter(pageList);
  return <RouterProvider router={router} />;
};
